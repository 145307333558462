<template>
<div class="page-wrapper">
    <Navigation />
    <div class="section about-us wf-section">
        <div data-w-id="081c0aac-b96f-7778-465c-c59646c49474" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="container-small-696px text-center w-container">
            <h1 class="special-2 about-us">The mission behind <span class="font-color-primary">Wish2Skill</span>.</h1>
            <p class="paragraph about-us">Relatable. Resourceful. Rewarding</p>
            <a href="#Our-Story" class="button-secondary large w-button">Our Story</a>
        </div>
        <div data-w-id="081c0aac-b96f-7778-465c-c59646c4947e" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="about-us-images"><img src="../../catalyft-ui-templates/images/image-about-us-01-academy-template.jpg" sizes="(max-width: 479px) 45vw, (max-width: 767px) 44vw, (max-width: 991px) 268px, 27vw" srcset="../../catalyft-ui-templates/images/image-about-us-01-academy-template-p-500.jpeg 500w, ../../catalyft-ui-templates/images/image-about-us-01-academy-template.jpg 784w" alt="About Gallery - Academy Webflow Template" class="image about-us-1">
            <div class="spacer about-images"></div><img src="../../catalyft-ui-templates/images/image-about-us-02-academy-template.jpg" sizes="(max-width: 479px) 45vw, (max-width: 767px) 44vw, (max-width: 991px) 307px, 31vw" srcset="../../catalyft-ui-templates/images/image-about-us-02-academy-template-p-800.jpeg 800w, ../../catalyft-ui-templates/images/image-about-us-02-academy-template.jpg 910w" alt="About Gallery - Academy Webflow Template" class="image about-us-2">
            <div class="spacer about-images"></div><img src="../../catalyft-ui-templates/images/image-about-us-03-academy-template.jpg" id="w-node-_081c0aac-b96f-7778-465c-c59646c49483-cf125207" alt="About Gallery - Academy Webflow Template" class="image about-us-3">
            <div class="spacer about-images"></div><img src="../../catalyft-ui-templates/images/image-about-us-04-academy-template.jpg" id="w-node-_081c0aac-b96f-7778-465c-c59646c49485-cf125207" alt="About Gallery - Academy Webflow Template" class="image about-us-1">
        </div>
    </div>
    <div data-w-id="081c0aac-b96f-7778-465c-c59646c49486" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section achievements wf-section">
        <div class="container-default-1209px w-container">
            <div class="achievements-wrapper">
                <div data-w-id="081c0aac-b96f-7778-465c-c59646c49489" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="achievement-wrapper">
                    <!-- <div class="achievement-number">7,000<span class="font-color-primary">+</span></div>
                    <div class="achievement-category">STUDENTS</div> -->
                </div>
                <div class="spacer achievement"></div>
                <div data-w-id="081c0aac-b96f-7778-465c-c59646c49491" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="achievement-wrapper">
                    <!-- <div class="achievement-number">100<span class="font-color-primary">+</span></div>
                    <div class="achievement-category">COURSES</div> -->
                </div>
                <div class="spacer achievement"></div>
                <div data-w-id="081c0aac-b96f-7778-465c-c59646c49499" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="achievement-wrapper">
                    <!-- <div class="achievement-number">800<span class="font-color-primary">+</span></div>
                    <div class="achievement-category">5-STAR REVIEWS</div> -->
                </div>
                <div class="spacer achievement"></div>
                <div data-w-id="081c0aac-b96f-7778-465c-c59646c494a1" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="achievement-wrapper">
                    <!-- <div class="achievement-number">35<span class="font-color-primary">+</span></div>
                    <div class="achievement-category">teachers</div> -->
                </div>
            </div>
        </div>
    </div>
    <div id="Our-Story" data-w-id="081c0aac-b96f-7778-465c-c59646c494a8" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section wf-section">
        <div class="container-default-1209px w-container">
            <div class="our-story-wrapper">
                <div data-w-id="081c0aac-b96f-7778-465c-c59646c494ab" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="split-content our-story">
                    <h2>Our Story</h2>
                    <p class="paragraph our-story-bold"><strong>We are a community of passionate thinkers whose mission is to improve the personal and professional lives of others. </strong></p>
                    <p>The world as we know it will never be the same after the pandemic. Technology saved everyone by restoring some kind of normalcy to our working and learning circumstances. Wish2Skill' mission is to leverage technology to make learning accessible to everybody and to establish ourselves as aggregators of self-development learning. Additionally, we believe in providing our consumers with the services that best meet their needs. Whether you prefer in-person training or online education, we can meet your demands with a blended approach that is highly customised and adjusted for maximum effectiveness.
                    </p>
                </div><img src="../../catalyft-ui-templates/images/image-our-story-academy-template.jpg" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" data-w-id="081c0aac-b96f-7778-465c-c59646c494b6" sizes="(max-width: 479px) 93vw, (max-width: 767px) 94vw, (max-width: 991px) 95vw, (max-width: 1439px) 49vw, 648.71875px" srcset="../../catalyft-ui-templates/images/image-our-story-academy-template-p-800.jpeg 800w, ../../catalyft-ui-templates/images/image-our-story-academy-template-p-1080.jpeg 1080w, ../../catalyft-ui-templates/images/image-our-story-academy-template.jpg 1186w" alt="About Gallery - Academy Webflow Template" class="image our-story">
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="081c0aac-b96f-7778-465c-c59646c494b8" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>
    <div data-w-id="081c0aac-b96f-7778-465c-c59646c494b9" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section mission wf-section">
        <div class="container-small-400px text-center w-container">
            <h2 data-w-id="081c0aac-b96f-7778-465c-c59646c494bb" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100">The Mission Behind Quest</h2>
        </div>
        <div class="container-default-1209px w-container">
            <div data-w-id="081c0aac-b96f-7778-465c-c59646c494be" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="slider-wrapper">
                <div data-delay="4000" data-animation="slide" class="slider w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">
                    <div class="w-slider-mask">
                        <div class="slide w-slide">
                            <div class="slide-wrapper"><img src="../../catalyft-ui-templates/images/icon-slider-01-academy-template.svg" alt="Degree Icon - Academy Webflow Template">
                                <h3>1. Localise Learning Content</h3>
                                <p class="paragraph slide">Neither Hollywood nor Bollywood films accurately depict reality. Similarly, , many online courses have the same effect on learners. We believe in making learning relatable by incorporating ideas, thoughts, material, and preferences into the program.</p>
                            </div>
                        </div>
                        <div class="slide w-slide">
                            <div class="slide-wrapper"><img src="../../catalyft-ui-templates/images/icon-slider-02-academy-template.svg" alt="Analytics - Academy Webflow Template">
                                <h3>2. Come and Visit Us</h3>
                                <p class="paragraph slide">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis cursus turpis in habitant sagittis amet dolor malesuada ut. Volutpat nunc id blandit vitae ut volutpat nunc nascetur varius. Sit felis sed sem duis.</p>
                            </div>
                        </div>
                        <div class="slide w-slide">
                            <div class="slide-wrapper"><img src="../../catalyft-ui-templates/images/icon-slider-03-academy-template.svg" alt="Mentorship - Academy Webflow Template">
                                <h3>3. Grow a Community</h3>
                                <p class="paragraph slide">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis cursus turpis in habitant sagittis amet dolor malesuada ut. Volutpat nunc id blandit vitae ut volutpat nunc nascetur varius. Sit felis sed sem duis.</p>
                            </div>
                        </div>
                    </div>
                    <div class="left-arrow w-slider-arrow-left">
                        <div class="slider-arrow-icon"></div>
                    </div>
                    <div class="right-arrow w-slider-arrow-right">
                        <div class="slider-arrow-icon"></div>
                    </div>
                    <div class="slide-nav w-slider-nav w-round"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="081c0aac-b96f-7778-465c-c59646c494dc" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>
    <div data-w-id="081c0aac-b96f-7778-465c-c59646c494dd" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section wf-section">
        <div data-w-id="081c0aac-b96f-7778-465c-c59646c494de" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="container-small-615px text-center w-container">
            <h2>Come and Visit Us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat feugiat congue viverra facilisis.</p>
        </div>
        <div class="container-default-1209px w-container">
            <div data-w-id="081c0aac-b96f-7778-465c-c59646c494e4" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="w-layout-grid visit-us-grid">
                <div class="card visit-us"><img src="../../catalyft-ui-templates/images/image-visit-us-01-academy-template.jpg" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 49vw, (max-width: 1439px) 96vw, 1272px" srcset="../../catalyft-ui-templates/images/image-visit-us-01-academy-template-p-1080.jpeg 1080w, ../../catalyft-ui-templates/images/image-visit-us-01-academy-template.jpg 1134w" alt="New York Office - Academy Webflow Template" class="image visit-us">
                    <div class="card-content visit-us">
                        <h3 class="title visit-us">{{title}}</h3>
                        <div class="visit-us-contact-wrapper">
                            <div class="visit-us-location-wrapper"><img src="../../catalyft-ui-templates/images/icon-event-03-academy-template.svg" alt="Location Icon - Academy Webflow Template" class="visit-us-icon location">
                                <div class="visit-us-location-text">216 Eddy St, San Francisco, California (CA), 94102</div>
                            </div>
                            <div class="spacer visit-us"></div>
                            <a href="tel:(415)655-9116" class="visit-us-phone-number-wrapper w-inline-block"><img src="../../catalyft-ui-templates/images/icon-contact-academy-template.svg" alt="Phone Icon - Academy Webflow Template" class="visit-us-icon">
                                <div class="visit-us-phone-number-text">(415) 655-9116</div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card visit-us"><img src="../../catalyft-ui-templates/images/image-visit-us-02-academy-template.jpg" sizes="(max-width: 479px) 93vw, (max-width: 767px) 91vw, (max-width: 991px) 49vw, (max-width: 1439px) 96vw, 1272px" srcset="../../catalyft-ui-templates/images/image-visit-us-02-academy-template-p-1080.jpeg 1080w, ../../catalyft-ui-templates/images/image-visit-us-02-academy-template.jpg 1134w" alt="San Francisco Office - Academy Webflow Template" class="image visit-us">
                    <div class="card-content visit-us">
                        <h3 class="title visit-us">{{title}}</h3>
                        <div class="visit-us-contact-wrapper">
                            <div class="visit-us-location-wrapper"><img src="../../catalyft-ui-templates/images/icon-event-03-academy-template.svg" alt="Location Icon - Academy Webflow Template" class="visit-us-icon location">
                                <div class="visit-us-location-text">643 Mundy St Watertown, New York (NY), 13601</div>
                            </div>
                            <div class="spacer visit-us"></div>
                            <a href="tel:(304)722-3754" class="visit-us-phone-number-wrapper w-inline-block"><img src="../../catalyft-ui-templates/images/icon-contact-academy-template.svg" alt="Phone Icon - Academy Webflow Template" class="visit-us-icon">
                                <div class="visit-us-phone-number-text">(304) 722-3754</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div data-w-id="081c0aac-b96f-7778-465c-c59646c49503" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="vc-flex">
                <router-link :to="{name:'Courses'}" class="button-primary large w-button">Our Courses</router-link>
            </div>
        </div>
    </div>
    <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b32" class="section cta wf-section">
        <div class="container-default-1209px w-container">
            <div class="cta-wrapper">
                <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b35" class="split-content cta-left">
                    <h2 class="title cta">Grow your career!<br>Start learning with Wish2Skill.</h2>
                    <p class="paragraph cta">Develop the skills essential to secure a job and fast-track your professional career.</p>
                    <router-link :to="{name:'Courses'}" class="button-secondary cta w-button">Our Courses</router-link>
                </div>
                <div data-w-id="458a3e47-e544-5eec-ffce-58aa64417b3e" class="split-content cta-right">
                    <img src="../../catalyft-ui-templates/images/image-cta-01-academy-template.svg" alt="Web App Courses - Academy Webflow Template" class="image cta-1">
                    <img src="../../catalyft-ui-templates/images/image-cta-04-academy-template.svg" alt="Marketing - Academy Webflow Template" class="image cta-4">
                    <img src="../../catalyft-ui-templates/images/image-cta-03-academy-template.svg" alt="Development Course - Academy Webflow Template" class="image cta-3">
                    <img src="../../catalyft-ui-templates/images/image-cta-2.svg" alt="Mobile App Course - Academy Webflow Template" class="image cta-2"></div>
                <img src="../../catalyft-ui-templates/images/circle-shape-cta-04-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b43" alt="" class="circle-shape-cta _4">
                <img src="../../catalyft-ui-templates/images/circle-shape-cta-03-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b44" alt="" class="circle-shape-cta _3">
                <img src="../../catalyft-ui-templates/images/circle-shape-cta-02-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b45" alt="" class="circle-shape-cta _2">
                <img src="../../catalyft-ui-templates/images/circle-shape-cta-01-academy-template.svg" data-w-id="458a3e47-e544-5eec-ffce-58aa64417b46" alt="" class="circle-shape-cta _1">
            </div>
        </div>
    </div>
    <div data-w-id="081c0aac-b96f-7778-465c-c59646c49506" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="section wf-section">
        <div class="container-default-1209px w-container">
            <div data-w-id="081c0aac-b96f-7778-465c-c59646c49508" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="top-content meet-our-teachers">
                <div class="split-content meet-our-teachers">
                    <h2>Meet Our Wish2Skill</h2>
                    <p class="paragraph mg-bottom-0px">Meet the Wish2Skill who will assist you in increasing your skills and abilities.</p>
                </div>
                <a href="" class="button-secondary w-button">ALL RENDERS</a>
            </div>
            <div class="w-dyn-list">
                <div role="list" class="meet-our-teachers-grid w-dyn-items" style="grid-template-columns: 1fr 0fr 0fr;">
                    <div data-w-id="081c0aac-b96f-7778-465c-c59646c49521" style="" role="listitem" class="teacher-card w-dyn-item">
                        <div class="card teachers">
                            <div class="teacher-card-wrapper">
                                <a data-w-id="83c68ba5-1401-8988-7b5f-a22e6cb431e0" href="#" class="card-title-link teachers w-inline-block"><img src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c54a901c6c4d_john-carter-academy.jpg" alt="" class="image teachers">
                                    <h3 class="title teachers">Jhon</h3>
                                </a>
                                <div class="teachers-work">Wish2Skill</div>
                                <p class="paragraph teachers-text">Happiness specialist, skill enabler, career catalyst and TEDx speaker. Over his 15 years of experience in transforming people, he has trained more than 3,00,000 people on various people development programs.</p>
                                <a href="#" class="button-secondary w-button">View Profile</a>
                            </div>
                        </div>
                    </div>
                    <!-- <div data-w-id="081c0aac-b96f-7778-465c-c59646c49521" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" role="listitem" class="teacher-card w-dyn-item">
                        <div class="card teachers">
                            <div class="teacher-card-wrapper">
                                <a data-w-id="83c68ba5-1401-8988-7b5f-a22e6cb431e0" href="#" class="card-title-link teachers w-inline-block"><img src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c54a901c6c4d_john-carter-academy.jpg" alt="" class="image teachers">
                                    <h3 class="title teachers">John Carter</h3>
                                </a>
                                <div class="teachers-work">Digital Marketer</div>
                                <p class="paragraph teachers-text">Lorem ipsum dolor sit amet, consectet adipiscing elit. Ut sit curabitur pellentesq sollicitudin malesuada aliquam risus.</p>
                                <a href="#" class="button-secondary w-button">View Profile</a>
                            </div>
                        </div>
                    </div>
                    <div data-w-id="081c0aac-b96f-7778-465c-c59646c49521" style="-webkit-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(0.97, 0.97, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" role="listitem" class="teacher-card w-dyn-item">
                        <div class="card teachers">
                            <div class="teacher-card-wrapper">
                                <a data-w-id="83c68ba5-1401-8988-7b5f-a22e6cb431e0" href="#" class="card-title-link teachers w-inline-block"><img src="https://assets.website-files.com/5f52d24bbe17c5455b1c6b7f/5f52d24bbe17c54a901c6c4d_john-carter-academy.jpg" alt="" class="image teachers">
                                    <h3 class="title teachers">John Carter</h3>
                                </a>
                                <div class="teachers-work">Digital Marketer</div>
                                <p class="paragraph teachers-text">Lorem ipsum dolor sit amet, consectet adipiscing elit. Ut sit curabitur pellentesq sollicitudin malesuada aliquam risus.</p>
                                <a href="#" class="button-secondary w-button">View Profile</a>
                            </div>
                        </div>
                    </div> -->
                </div>
                
            </div>
        </div>
    </div>
    <div class="container-default-1209px w-container">
        <div data-w-id="081c0aac-b96f-7778-465c-c59646c49526" style="-webkit-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 48PX, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:100" class="divider"></div>
    </div>
    <Footer />
</div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
    data() {
        return {
            title: ''
        }
    },
    components: {
        Navigation,
        Footer
    },
    created() {
        this.title = process.env.VUE_APP_Title
    },
}
</script>
